import { useEffect, useState } from "react";

// Chakra imports
import { Flex, FormControl, Button, Text, useColorModeValue, useToast } from "@chakra-ui/react";
import Card from "../../../../../../components/card/Card.js";
import InputField from "../../../../../../components/fields/InputField";

import { ApiHeader } from '../../../../../../_helpers/ApiHeader';


export default function Socials(props) {
  const {
    userData,
    setUserData,
    isLoading,
    saveData,
    ...rest 
  } = props;
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.600";
  // Chakra Color Mode

  // useEffect(()=>{
  //   setUserData({
  //     telegramhandle: props.userData.telegramhandle,
  //     discordhandle: props.userData.discordhandle,
  //     twitterhandle: props.userData.twitterhandle,
  //   });
  // },[props])

  // const saveSocialHandles = () => {
  //   setIsLoading(true);

  //   let postData = {};

  //   userData.telegramhandle ? postData.telegramhandle = userData.telegramhandle : postData.telegramhandle = "";
  //   userData.discordhandle ? postData.discordhandle = userData.discordhandle : postData.discordhandle = "";
  //   userData.twitterhandle ? postData.twitterhandle = userData.twitterhandle : postData.twitterhandle = ""; 

  //   fetch(process.env.REACT_APP_API_URL+'api/user/addUserData', {
  //     method: 'POST',
  //     body: JSON.stringify(postData),
  //     headers: ApiHeader('auth')
  //   })
  //   .then(response => { 
  //     return response.json();
  //   })
  //   .then(responseData => {
  //     return responseData;
  //   })
  //   .then(data => {
  //     setIsLoading(false);
  //     console.log(data)
  //     if (data.status === 1) {
  //       toast({
  //         title: 'Data saved',
  //         description: "Data was succesfully saved",
  //         status: 'success',
  //       })
  //     } else {
  //       toast({
  //           title: 'Error.',
  //           description: data.message,
  //           status: 'error',
  //         })
  //     }
  //   })
  //   .catch(err => {
  //     toast({
  //         title: 'Error.',
  //         description: "Error, please try again",
  //         status: 'error',
  //       })
  //       console.log("fetch error: " + err);
  //       setIsLoading(false);
  //   });
  // }

  return (
    <FormControl>
      <Card mb='20px' {...rest}>
        <Flex direction='column' mb='30px' ms='10px'>
          <Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
            Social Profiles
          </Text>
          <Text fontSize='md' color={textColorSecondary}>
            Here you can set user social profiles
          </Text>
        </Flex>
        <InputField
          mb='25px'
          id='twitter_username'
          label='Twitter Username'
          placeholder='Twitter Username'
          value={userData.twitterhandle}
          onChange={(e) => setUserData({ ...userData, twitterhandle: e.target.value })}
        />
        <InputField
          mb='25px'
          id='telegram_username'
          label='Telegram Username'
          placeholder='Telegram Username'
          value={userData.telegramhandle}
          onChange={(e) => setUserData({ ...userData, telegramhandle: e.target.value })}
        />
        <InputField
          mb='25px'
          id='discord_username'
          label='Discord Username'
          placeholder='Discord Username'
          value={userData.discordhandle}
          onChange={(e) => setUserData({ ...userData, discordhandle: e.target.value })}
        />

        <Button
          variant='setup'
          mt='24px'
          px='24px'
          onClick={saveData}
          fontSize='md'
          fontWeight='500'>
          Save
        </Button>
      </Card>
    </FormControl>
  );
}
