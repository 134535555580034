import React from 'react';

import { Icon } from '@chakra-ui/react';
import { MdHome } from 'react-icons/md';
import { BiSolidUser } from 'react-icons/bi';
import { RiTeamFill } from 'react-icons/ri';

// Admin Imports
import Dashboard from './views/dashboard/main/main';
import AccountPage from './views/dashboard/account/main';
import ReferralsPage from './views/dashboard/referrals/main';

import BuyCoinClashTokens from './views/dashboard/buycct';
import TopupCoinClashGames from './views/dashboard/topup';

const routes = [
	{
		name: 'Main',
		layout: '/admin',
		path: '/home',
		icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
		collapse: false,
		show:true,
		component: <Dashboard/>
	},
	{
		name: 'Buy ClashChain Tokens',
		layout: '/admin',
		path: '/buy-cct',
		collapse: false,
		show:false,
		component: <BuyCoinClashTokens/>
	},
	{
		name: 'Topup Coin Clash Games account',
		layout: '/admin',
		path: '/topup',
		collapse: false,
		show:false,
		component: <TopupCoinClashGames/>
	},
	{
		name: 'Account',
		layout: '/admin',
		path: '/account',
		icon: <Icon as={BiSolidUser} width='20px' height='20px' color='inherit' />,
		collapse: false,
		show:true,
		component: <AccountPage/>
	},
	{
		name: 'Referrals',
		layout: '/admin',
		path: '/referrals',
		icon: <Icon as={RiTeamFill} width='20px' height='20px' color='inherit' />,
		collapse: false,
		show:true,
		component: <ReferralsPage/>
	}
];

export default routes;
