import React from "react";

// Chakra imports
import {
  Flex,
  Box,
  Icon,
  Select,
  Text,
  Image,
  useColorModeValue,
} from "@chakra-ui/react";
import LineChart from "../../../../../components/charts/LineChart";

// Custom components
import Card from "../../../../../components/card/Card.js";
import joker from "../../../../../assets/img/jokerclashchain.png";



export default function CCTPrice(props) {
  const { ...rest } = props;

  const lineChartDataOverallRevenue = [
    {
      name: "Price",
      data: [0.04, 0.04, 0.04, 0.04, 0.08, 0.1],
    },
  ];

  const lineChartOptionsOverallRevenue = {
    chart: {
      toolbar: {
        show: false,
      },
      dropShadow: {
        enabled: true,
        top: 13,
        left: 0,
        blur: 10,
        opacity: 0.1,
        color: "#4318FF",
      },
    },
    colors: ["#4318FF", "#39B8FF"],
    forecastDataPoints: {
      count: 2
    },
    markers: {
      size: 0,
      colors: "white",
      strokeColors: "#7551FF",
      strokeWidth: 3,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: "circle",
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      showNullDataPoints: true,
    },
    tooltip: {
      theme: "dark",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      type: "line",
    },
    xaxis: {
      type: "numeric",
      categories: ["JUL", "AVG", "SEP", "OCT", "NOV", "DEC"],
      labels: {
        style: {
          colors: "#A3AED0",
          fontSize: "12px",
          fontWeight: "500",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      min: 0,
      max: 0.15
    },
    legend: {
      show: false,
    },
    grid: {
      show: false,
      column: {
        color: ["#7551FF"],
        opacity: 0.5,
      },
    },
    color: ["#7551FF"],
  };

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  return (
    <Card
      justifyContent='center'
      align='center'
      direction='column'
      w='100%'
      mb={{ base: "20px", lg: "0px" }}
      {...rest}>
      <Flex justify='space-between' px='20px' pt='5px'>
        <Flex align='center'>
          <Flex flexDirection='column' me='20px'>
            <Text
              color='secondaryGray.600'
              fontSize='sm'
              fontWeight='500'
              mt='4px'>
               Clash Chain Token
            </Text>
            <Text
              color='secondaryGray.600'
              fontSize='sm'
              fontWeight='500'
              mb='12px'>
               (CCT) Price
            </Text>
            <Text
              color={textColor}
              fontSize='34px'
              fontWeight='700'
              lineHeight='100%'>
              $0.1
            </Text>
          </Flex>
        </Flex>
        <Image 
          mt='32px'
          maxH='200px'
          src={joker} />
      </Flex>

      <Flex direction='column' justify='space-between' align='start' mt='18px' mb='18px'>
        <Text fontSize='xl' fontWeight='bold'>
          Fantastic discounts for game enthusiasts!
        </Text>
        <Text align='left'>
          Beginning on October 1st, players of our games can enjoy savings of up to 60% on token prices. Plus, as you reach specific gaming milestones, you'll become eligible to claim your very own Clash Chain Pass NFT. This special pass enables you to purchase coins at discounted rates.
        </Text>
        <Text align='left'>        
        Stay tuned for the unveiling of our four distinct tiers. Get ready for an enhanced gaming experience!
        </Text>
      </Flex>
      {/* <Box minH='260px' mt='auto'>
        <LineChart
          chartData={lineChartDataOverallRevenue}
          chartOptions={lineChartOptionsOverallRevenue}
        />
      </Box> */}
    </Card>
  );
}
