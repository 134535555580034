
// Chakra imports
import { Flex, Grid, useColorModeValue, Button } from "@chakra-ui/react";
// Custom components
import { VSeparator } from "../../../components/separator/Separator";

import { useState, useEffect } from "react";

import CCTPrice from "../../../views/dashboard/main/main/components/CCTPrice";
import BuyTokens from "../../../views/dashboard/main/main/components/BuyTokens";
import DealAndResources from "../../../views/dashboard/main/main/components/DealAndResources";
import CoinClashGamesConnect from "../../../views/dashboard/main/main/components/CoinClashGamesConnect";

import Card from "../../../components/card/Card.js";

import { GetUserData } from "../../../_helpers/Auth";

import solitairerdare from "../../../assets/img/solitairerdare.jpg";
import bubbleclash from "../../../assets/img/bubbleclash.jpg";


export default function Default() {

  const [user, setUser] = useState('');

  useEffect(() => {
    setUser(GetUserData());
  }, []);

  // Chakra Color Mode
  const paleGray = useColorModeValue("secondaryGray.400", "whiteAlpha.100");
  return (
    <Flex
      direction={{ base: "column", xl: "row" }}
      pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Flex direction='column' width='stretch'>
        <Grid
          gap='20px'
          gridTemplateColumns={{
            md: "repeat(2, 1fr)",
            "2xl": "repeat(3, 1fr)",
          }}
          gridTemplateRows={{
            md: "repeat(2, 1fr)",
            "2xl": "1fr",
          }}
          mb='20px'>
            <Card
              bgSize='cover'
              w=''
              gridArea={{ md: "1 / 1 / 2 / 2", "2xl": "1 / 1 / 2 / 2" }}
              backgroundPosition='center center'
              minH={{ base: "310px", md: "100%" }}
              bgImage={solitairerdare}>
              <Button
                variant='no-hover'
                w='max-content'
                backdropFilter='blur(11px)'
                borderRadius='70px'
                mt='auto'
                fontSize='sm'
                bg='linear-gradient(112.83deg, rgba(255, 255, 255, 0.52) 0%, rgba(255, 255, 255, 0) 110.84%)'
                color='white'
                fontWeight='bold'
                onClick={() => window.open("https://www.solitairedare.com/", "_blank")}>
                Play the game
              </Button>
            </Card>
            <Card
              bgSize='cover'
              w=''
              gridArea={{ md: "1 / 2 / 2 / 3", "2xl": "1 / 2 / 2 / 3" }}
              backgroundPosition='center center'
              minH={{ base: "310px", md: "100%" }}
              bgImage={bubbleclash}>
              <Button
                variant='no-hover'
                w='max-content'
                backdropFilter='blur(11px)'
                borderRadius='70px'
                mt='auto'
                fontSize='sm'
                bg='linear-gradient(112.83deg, rgba(255, 255, 255, 0.52) 0%, rgba(255, 255, 255, 0) 110.84%)'
                color='white'
                fontWeight='bold' 
                onClick={() => window.open("https://www.bubbleclash4cash.com/", "_blank")}>
                Play the game
              </Button>
            </Card>
          <Flex gridArea={{ md: " 2 / 1 / 3 / 3", "2xl": "1 / 3 / 2 / 4" }}>
            <CoinClashGamesConnect user={user}/>
          </Flex>
        </Grid>
        <Grid
          mb='20px'
          gridTemplateColumns={{ base: "repeat(2, 1fr)", "2xl": "720fr 350fr" }}
          gap='20px'
          display={{ base: "block", lg: "grid" }}>
          <Flex gridArea={{ base: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}>
            <CCTPrice />
          </Flex>
          <Flex gridArea={{ base: "2 / 1 / 3 / 3", "2xl": "1 / 2 / 2 / 3" }}>
            <BuyTokens />
          </Flex>
        </Grid>
        {/* <Grid
          templateColumns={{ base: "repeat(2, 1fr)", "2xl": "350fr 720fr" }}
          gap='20px'
          display={{ base: "block", lg: "grid" }}>
          <Flex gridArea={{ base: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}>
            <YourTransfers />
          </Flex>
          <Flex gridArea={{ base: "2 / 1 / 3 / 3", "2xl": "1 / 2 / 2 / 3" }}>
            <MostVisitedTable
              tableData={tableDataMostVisited}
              columnsData={tableColumnsMostVisited}
            />
          </Flex>
        </Grid> */}
      </Flex>
      <VSeparator
        mx='20px'
        bg={paleGray}
        display={{ base: "none", xl: "flex" }}
      />
      <DealAndResources
        maxW={{ base: "100%", xl: "400px" }}
        maxH={{ base: "100%", xl: "1170px", "2xl": "100%" }}
      />
    </Flex>
  );
}
