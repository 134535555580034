// Chakra imports
import {
  Flex,
  FormControl,
  SimpleGrid,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import Card from "../../../../../../components/card/Card.js";
import SetUp from "../../../../../../components/actions/SetUp";
import React from "react";
import { ethers } from 'ethers';
import { ApiHeader } from '../../../../../../_helpers/ApiHeader';

export default function Connected(props) {
  const { 
    userData,
    setUserData,
    ...rest } = props;
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("secondaryGray.400", "whiteAlpha.100");
  // Chakra Color Mode

  const toast = useToast();

  const web3connect = async (event) => {
    event.preventDefault();

    try {
      if (window.ethereum) {
        const provider = new ethers.BrowserProvider(window.ethereum)
        const signer = await provider.getSigner()
        provider.send("eth_requestAccounts", []).then(async () => {
          const address = await signer.getAddress()
          RegisterAddress(signer, address);
        })
      } else {
        toast({
            title: 'Error.',
            description: "No web3 wallet found in your browser.",
            status: 'error',
          })
      }

    } catch (ex) {
      console.log(ex)
      toast({
          title: 'Error.',
          description: "Error has occured.",
          status: 'error',
        })
    }
  }

  const RegisterAddress = async (signer, address) => {
    const msg = `Connect to Clash Chain.`
    const signature = await signer.signMessage(msg);
    
    fetch(process.env.REACT_APP_API_URL+'api/user/addaddress', {
      method: 'POST',
      body: JSON.stringify({
        "wallet" : address,
        "signature" : signature,
      }),
      headers: ApiHeader('auth')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data1 => {
      console.log(data1)
      setUserData({ ...userData, wallet_address: address })
    })
    .catch(err => {
      toast({
          title: 'Error.',
          description: "Error authenticating with smart wallet",
          status: 'error',
        })
        console.log("fetch error: " + err);
    });
  }

  return (
    <FormControl>
      <Card p='30px' {...rest}>
        <Flex justify='space-between' align='center'>
          <Text fontSize='2xl' color={textColorPrimary} fontWeight='bold'>
            Connections
          </Text>
        </Flex>
        <SimpleGrid
          columns={{ sm: 1, md: 1, xl: 1 }}
          spacing={{ base: "20px", xl: "0px" }}>
          {/* <SetUp
            py='20px'
            borderBottom='1px solid'
            borderColor={borderColor}
            name='Google Account'
            actionName='Connect'
          /> */}
          <SetUp
            py='20px'
            borderBottom='1px solid'
            borderColor={borderColor}
            name='WEB3 wallet'
            actionName='Connect'
            value={userData.wallet_address}
            action={web3connect}
          />
        </SimpleGrid>
      </Card>
    </FormControl>
  );
}
