import { useEffect, useState } from "react";
// Chakra imports
import {
  Flex,
  FormControl,
  Button,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "../../../../../../components/card/Card.js";
import SwitchField from "../../../../../../components/fields/SwitchField";

import { ApiHeader } from '../../../../../../_helpers/ApiHeader';

export default function Newsletter(props) {
  const {
    userData,
    setUserData,
    isLoading,
    saveData,
    ...rest 
  } = props;
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  // Chakra Color Mode


  return (
    <FormControl>
      <Card p='30px' mb='20px' {...rest}>
        <Flex mb='40px' justify='space-between' align='center'>
          <Text fontSize='2xl' color={textColorPrimary} fontWeight='bold'>
            Newsletter
          </Text>
        </Flex>
        <SwitchField
          mb='25px'
          me='30px'
          id='1'
          label='Weekly newsletter'
          desc='Get notified about articles, discounts and new products.'
          isChecked={userData.weeklynewsletter}
          onChange={(e) => setUserData({ ...userData, weeklynewsletter: e.target.checked })}
        />
        <SwitchField
          me='30px'
          id='4'
          textWidth='60%'
          label='Product updates'
          desc='Checking this will allow us to notify you when we make updates to products you have downloaded/purchased.'
          isChecked={userData.productupdates}
          onChange={(e) => setUserData({ ...userData, productupdates: e.target.checked })}
        /> 
      </Card>
    </FormControl>
  );
}
