import { useState, useEffect } from "react";

// Chakra imports
import {
  Flex,
  Button,
  Image,
  Text,
  Icon,
  IconButton,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
  SimpleGrid,
  Stack,
  Hide,
} from "@chakra-ui/react";
import { useDisclosure } from '@chakra-ui/react'
import { useNavigate } from "react-router-dom";

// Custom components
import Card from "../../../../../components/card/Card.js";

// Assets
import balanceImg from "../../../../../assets/img/balanceImg.png";
import exchange from "../../../../../assets/img/exchange.svg";

import bubbleclash from "../../../../../assets/img/bubbleclash.jpg";

import InputField from "../../../../../components/fields/InputField";

import { conversionApi, conversionApiBTC, conversionApiBNB, bitclashToUstc, formatNumber} from '../../../../../_helpers/Conversions';
import { ApiHeader } from '../../../../../_helpers/ApiHeader';
import { Logout, SetCCGAuth, GetCCGAuth, IsCCGValid } from '../../../../../_helpers/Auth';


import {
  MdCached,
  MdAdd,
  MdAttachMoney,
  MdMoreHoriz,
} from "react-icons/md";

import {
  AiOutlineDisconnect,
} from "react-icons/ai";

export default function CoinClashGamesConnect(props) {
  const { 
    user,
    ...rest } = props;

  const toast = useToast();
  const navigate = useNavigate();
  
  const [connected, setConnected] = useState(false);

  const [password, setPassword] = useState('');

  const [balance, setBalance] = useState('-');

  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorWarning = useColorModeValue("red.500", "red.300");
  const yellowIcon = useColorModeValue("yellow.500", "white");

  const bgIconButton = useColorModeValue("white", "whiteAlpha.100");
  const bgIconHover = useColorModeValue(
    { bg: "gray.100" },
    { bg: "whiteAlpha.50" }
  );
  const bgIconFocus = useColorModeValue(
    { bg: "grey.100" },
    { bg: "whiteAlpha.100" }
  );

  const bgIconBrandButton = useColorModeValue("brand.500", "brand.400");
  const bgIconBrandButtonHover = useColorModeValue(
    { bg: "brand.100" },
    { bg: "brand.50" }
  );
  const bgIconBrandButtonFocus = useColorModeValue(
    { bg: "brand.100" },
    { bg: "brand.100" }
  );

  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.100" }
  );
  const shadow = useColorModeValue(
    "18px 17px 40px 4px rgba(112, 144, 176, 0.1)",
    "unset"
  );

  useEffect(()=>{
    isConnected();
  },[])

  const isConnected = () => {

    fetch(process.env.REACT_APP_API_URL+'api/user/userccdata', {
      method: 'GET',
      headers: ApiHeader('auth')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      console.log(data)

      if (data.status === 1) {
        setConnected(data.connected_games)

        if (!data.connected_games) {
          ConnectOverlay()
        }
      } else if (data.status === 401) {
        console.log('401')
        console.log(data.message)
        Logout();
      }
      
    })
    .catch(err => {
        console.log("fetch error: " + err);
    });
  }

  useEffect(()=>{
    console.log("connected started")
    if (connected) {
      tokenCCGames();
    }
  },[connected])

  const tokenCCGames = () => {

    fetch(process.env.REACT_APP_API_URL+'api/user/cctoken', {
      method: 'GET',
      headers: ApiHeader('auth')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      console.log(data)

      if (data.status === 1) {
        SetCCGAuth(data.token)
        getBalance(data.token);
      } else if (data.status === 401) {
        console.log('401')
        console.log(data.message)
        Logout();
      }
    })
    .catch(err => {
        console.log("fetch error: " + err);
    });
  }

  const getBalance = (jwttoken) => {

    console.log(GetCCGAuth())

    fetch('https://api.cryptoclash.games/v2/account/financials', {
      method: 'GET',
      headers: { 
        'Authorization': 'Bearer ' + jwttoken,
        'Content-Type': 'application/json'
      }
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      console.log(data)

      if (data.coins) {
        setBalance(`$${data.coins/100}`)
      }
    })
    .catch(err => {
        console.log("fetch error: " + err);
    });
  }

  const { isOpen, onOpen, onClose } = useDisclosure();

  const ConnectOverlay = () => {
    onOpen()
  }

  const connectCCGames = () => {

    if (password==='') {
      toast({
        title: 'Error.',
        description: "Please enter password",
        status: 'error',
      });
      return;
    }
    fetch(process.env.REACT_APP_API_URL+'api/user/userccconnect', {
      method: 'POST',
      body: JSON.stringify({
        "password" : password
      }),
      headers: ApiHeader('auth')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      console.log(data);
      if (data.status === 1) {
        setConnected(true)
        SetCCGAuth(data.token)
        getBalance(data.token);
        
        toast({
          title: 'Account connected',
          description: "Account connected",
          status: 'success',
        })
      } else if (data.status === 401) {
        console.log('401')
        console.log(data.message)
        Logout();
      }
    })
    .catch(err => {
        console.log("fetch error: " + err);
    })
    .finally (() => {
      onClose();
    })
  }

  const disconnectCCGames = () => {

    fetch(process.env.REACT_APP_API_URL+'api/user/cctoken', {
      method: 'POST',
      body: JSON.stringify({
        "password" : password
      }),
      headers: ApiHeader('auth')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      console.log(data)

      if (data.status === 1) {
        SetCCGAuth(data.token)
        getBalance(data.token);
      } else if (data.status === 401) {
        console.log('401')
        console.log(data.message)
        Logout();
      }
    })
    .catch(err => {
        console.log("fetch error: " + err);
    });
  }

  // Chakra Color Mode
  const balanceBg = useColorModeValue("brand.900", "#1B254B");
  const bgModal = useColorModeValue("#1B254B", "#1B254B");
  
  return (
    <Card direction='column' w='100%' {...rest}>
      {!connected && (
        <>
          <Flex
            direction='column'
            bg={boxBg}
            p='16px 20px'
            borderRadius='14px'
            mb='38px'>
            <Text fontSize='sm' fontWeight='700' color={textColor}>
              Coin Clash Games
            </Text>
            <Text fontSize='sm' fontWeight='500' color='secondaryGray.600'>
              Connect to your Coin Clash Games account and use to your playing account with crypto or receive 10% topup from token purchase.
            </Text>
          </Flex>
          <Button 
            variant='brand' 
            borderRadius='70px' 
            w='100%' 
            mb='38px' 
            ms='auto'
            onClick={() => ConnectOverlay()}>
            Connect Coin Clash Games
          </Button>
        </>
      )}
      {connected && (
        <>
          <Flex
            direction='column'
            bg={boxBg}
            p='16px 20px'
            borderRadius='14px'
            mb='38px'>
            <Text fontSize='sm' fontWeight='700' color={textColor}>
              Coin Clash Games
            </Text>
            <Text fontSize='sm' fontWeight='500' color='secondaryGray.600'>
              You've connected to Coin Clash Games.
            </Text>
          </Flex>
        </>
      )}
      <Flex
        justify='space-between'
        p='20px'
        mb='20px'
        borderRadius='16px'
        bgColor={balanceBg}
        bgImage={balanceImg}
        bgPosition='right'
        bgSize='cover'>
        <Flex align='center' justify='space-between' w='100%'>
          <Flex flexDirection='column' me='20px'>
            <Text color='white' fontSize='sm' fontWeight='500'>
              Your Coin Clash Games balance
            </Text>
            <Text
              color='white'
              fontSize='34px'
              fontWeight='700'
              lineHeight='100%'>
              {balance}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex mx='auto' mb='0px'>
        <Flex
          direction='column'
          align='center'
          
          me={{ base: "22px", md: "40px", "2xl": "36px" }}>
          <IconButton
            borderRadius='50%'
            bg={bgIconBrandButton}
            _hover={bgIconBrandButtonHover}
            _active={bgIconBrandButtonFocus}
            _focus={bgIconBrandButtonFocus}
            w='56px'
            h='56px'
            mb='5px'
            boxShadow={shadow} 
            onClick={() => navigate('/admin/topup')}
            icon={<Icon as={MdCached} color={yellowIcon} w='24px' h='24px' />}
          />
          <Text fontSize='sm' fontWeight='500' color={textColor}>
            Top Up
          </Text>
        </Flex>
        {/* <Flex
          direction='column'
          align='center'
          me={{ base: "22px", md: "40px", "2xl": "36px" }}>
          <IconButton
            borderRadius='50%'
            bg={bgIconButton}
            _hover={bgIconHover}
            _active={bgIconFocus}
            _focus={bgIconFocus}
            w='56px'
            h='56px'
            mb='5px'
            boxShadow={shadow}
            icon={<Icon as={MdAttachMoney} color={yellowIcon} w='24px' h='24px' />}
          />
          <Text fontSize='sm' fontWeight='500' color={textColor}>
            Witdraw
          </Text>
        </Flex> */}
        {/* <Flex
          direction='column'
          align='center'
          me={{ base: "22px", md: "40px", "2xl": "36px" }}>
          <IconButton
            borderRadius='50%'
            bg={bgIconButton}
            _hover={bgIconHover}
            _active={bgIconFocus}
            _focus={bgIconFocus}
            w='56px'
            h='56px'
            mb='5px'
            boxShadow={shadow}
            icon={<Icon as={MdMoreHoriz} color={yellowIcon} w='24px' h='24px' />}
          />
          <Text fontSize='sm' fontWeight='500' color={textColor}>
            Manage
          </Text>
        </Flex> */}
      </Flex>
      <Modal isCentered isOpen={isOpen} size='4xl' onClose={onClose}>
        <ModalOverlay
          backdropFilter='blur(10px)'
        />
        <ModalContent
          borderWidth='1px' 
          borderRadius='lg' 
        >
          <ModalHeader
          borderWidth='1px' 
          borderTopRadius='lg' 
          bg='brand.400' >
            Connect with Coin Clash Games
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>

          <SimpleGrid columns={{ base: "1", md: "2" }} mt='20px' gap='20px'>
            <Hide below='md'>
              <Stack direction='column' gap='10px'>
                <Card
                  bgSize='cover'
                  w=''
                  backgroundPosition='center center'
                  minH={{ base: "310px", md: "100%" }}
                  bgImage={bubbleclash}>
                </Card>
              </Stack>
            </Hide>
            <Stack direction='column' gap='10px'>
              <Text textAlign='center' color={textColor} fontWeight='400' mt='20px'>
                Connect your Coin Clash Games account.
              </Text>
              <Text textAlign='center' color={textColor} fontWeight='400' mb='20px'>
                You have to use the same email address.
              </Text>
              <Text color={textColor} ml='20px' fontSize='l'>
                Email
              </Text>
              <InputField
                  mb='20px'
                  me='30px'
                  id='email'
                  placeholder='Email'
                  value={user.email}
                />
                <Text color={textColor} ml='20px' fontSize='l'>
                  Password
                </Text>
                <InputField
                    mb='20px'
                    me='30px'
                    id='password'
                    placeholder='Password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                
                <Text textAlign='center' color={textColorWarning} fontWeight='400' mt='20px'>
                  CHOOSE A PASSWORD YOU WILL USE IN GAMES.
                </Text>
                <Text textAlign='center' color={textColorWarning} fontWeight='400' mb='20px'>
                  WE RECOMMEND A DIFFERENT ONE THAN<br></br>YOU USE ON THIS PLATFORM.
                </Text>

              </Stack>

            </SimpleGrid>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='darkBrand'
              fontSize='sm'
              borderRadius='16px'
              w={{ base: "168px", md: "188px" }}
              h='46px'
              m='auto'
              onClick={() => connectCCGames()}>
              CONNECT
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Card>
  );
}
