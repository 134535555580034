import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  ChakraProvider,
} from '@chakra-ui/react';

import theme from "./theme/theme";

import { ProtectRoutes } from './_helpers/Auth';

import LoginPage from './views/auth/login/login'
import RegisterPage from './views/auth/register/register'
import ForgotPasswordPage from './views/auth/forgot-password/forgot'
import ResetPasswordPage from './views/auth/reset-password/reset'
import VerificationPage from './views/auth/verification/verification'
import Page404 from './views/common/404/404'

import AdminLayout from "./layouts/admin";

function App() {

  return (
    <ChakraProvider theme={theme} toastOptions={{ defaultOptions: { position: 'top-center', isClosable: true, duration: 4000, } }}>
      <Router>
        <Routes>
          <Route exact path="/" element={<LoginPage/>} />
          <Route exact path="/auth/login" element={<LoginPage/>} />
          <Route exact path="/auth/register" element={<RegisterPage/>} />
          <Route exact path="/auth/forgot-password" element={<ForgotPasswordPage/>} />
          <Route exact path="/auth/reset-password" element={<ResetPasswordPage/>} />
          <Route exact path="/auth/verification" element={<VerificationPage/>} />

          <Route element={ <ProtectRoutes /> }>
            <Route path='/admin/*' element={ <AdminLayout /> } />
          </Route>

          <Route path="*" element={<Page404/>} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
