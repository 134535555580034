
import { Box, Flex, SimpleGrid, useToast } from "@chakra-ui/react";
import Connected from "../../../views/dashboard/main/account/settings/components/Connected";
import Delete from "../../../views/dashboard/main/account/settings/components/Delete";
import Information from "../../../views/dashboard/main/account/settings/components/Information";
import Newsletter from "../../../views/dashboard/main/account/settings/components/Newsletter";
import Socials from "../../../views/dashboard/main/account/settings/components/Socials";
import { useEffect, useState } from "react";
import { ApiHeader } from '../../../_helpers/ApiHeader';
import { Logout } from '../../../_helpers/Auth';

export default function Settings() {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const [userData, setUserData] = useState({
    username: "",
    email: "",
    firstname: "",
    lastname: "",
    address: "",
    country: "",
    province: "",
    zipcode: "",
    city: "",
    telegramhandle: "",
    discordhandle: "",
    twitterhandle: "",
    weeklynewsletter: false,
    productupdates: false,
  });
  // Chakra Color Mode
  useEffect(()=>{
    getUserData();
  },[])

  useEffect(()=>{
    console.log(userData)
  },[userData])

  const getUserData = () => {
    setIsLoading(true);


    fetch(process.env.REACT_APP_API_URL+'api/user/userData', {
      method: 'GET',
      headers: ApiHeader('auth')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      setIsLoading(false);
      console.log(data)
      if (data.status === 1) {
        setUserData({
          username: data.username? data.username: "",
          email: data.email? data.email: "",
          firstname: data.first_name? data.first_name: "",
          lastname: data.last_name ? data.last_name: "",
          address: data.address ? data.address: "",
          country: data.country ? data.country: "",
          province: data.province ? data.province: "",
          zipcode: data.zip_code ? data.zip_code: "",
          city: data.city ? data.city: "",
          telegramhandle: data.telegramhandle ? data.telegramhandle: "",
          discordhandle: data.discordhandle ? data.discordhandle: "",
          twitterhandle: data.twitterhandle ? data.twitterhandle: "",
          weeklynewsletter: data.weeklynewsletter ? data.weeklynewsletter: false,
          productupdates: data.productupdates ? data.productupdates: false,
          wallet_address: data.wallet_address ? data.wallet_address: "",
        })

      } else if (data.status === 401) {
        console.log('401')
        console.log(data.message)
        Logout();
      } else {
        console.log(data.message)
        toast({
            title: 'Error.',
            description: data.message,
            status: 'error',
          })
      }
    })
    .catch(err => {
      toast({
          title: 'Error.',
          description: "Error, please try again",
          status: 'error',
        })
        console.log("fetch error: " + err);
        setIsLoading(false);
    });
  }

  const saveData = () => {
    console.log("save data")
    console.log(userData);

    setIsLoading(true);

    let postData = {};

    userData.address ? postData.address = userData.address : postData.address = "";
    userData.country ? postData.country = userData.country : postData.country = "";
    userData.province ? postData.province = userData.province : postData.province = ""; 
    userData.zipcode ? postData.zipCode = userData.zipcode : postData.zipCode = "";
    userData.city ? postData.city = userData.city : postData.city = "";
    userData.firstname ? postData.firstName = userData.firstname : postData.firstName = "";
    userData.lastname ? postData.lastName = userData.lastname : postData.lastName = "";
    userData.username ? postData.username = userData.username : postData.username = "";
    userData.telegramhandle ? postData.telegramhandle = userData.telegramhandle : postData.telegramhandle = "";
    userData.discordhandle ? postData.discordhandle = userData.discordhandle : postData.discordhandle = "";
    userData.twitterhandle ? postData.twitterhandle = userData.twitterhandle : postData.twitterhandle = "";
    userData.weeklynewsletter ? postData.weeklynewsletter = userData.weeklynewsletter : postData.weeklynewsletter = false;
    userData.productupdates ? postData.productupdates = userData.productupdates : postData.productupdates = false;

    fetch(process.env.REACT_APP_API_URL+'api/user/addUserData', {
      method: 'POST',
      body: JSON.stringify(postData),
      headers: ApiHeader('auth')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      setIsLoading(false);
      console.log(data)
      if (data.status === 1) {
        toast({
          title: 'Data saved',
          description: "Data was succesfully saved",
          status: 'success',
        })

      } else if (data.status === 401) {
        console.log('401')
        console.log(data.message)
        Logout();
      } else {
        toast({
            title: 'Error.',
            description: data.message,
            status: 'error',
          })
      }
    })
    .catch(err => {
      toast({
          title: 'Error.',
          description: "Error, please try again",
          status: 'error',
        })
        console.log("fetch error: " + err);
        setIsLoading(false);
    });
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1, lg: 2 }}
        spacing={{ base: "20px", xl: "20px" }}>
        {/* Column Left */}
        <Flex direction='column'>
          <Information userData={userData} isLoading={isLoading} setUserData={setUserData} saveData={saveData} />
          <Connected mb='20px' userData={userData} setUserData={setUserData} isLoading={isLoading} />
        </Flex>
        {/* Column Right */}
        <Flex direction='column'>
          <Newsletter mb='20px' userData={userData} isLoading={isLoading} setUserData={setUserData} saveData={saveData} />
          <Socials userData={userData} isLoading={isLoading} setUserData={setUserData} saveData={saveData} />
          {/* <Delete /> */}
        </Flex>
      </SimpleGrid>
    </Box>
  );
}
