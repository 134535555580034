import { useState } from "react";
// Chakra imports
import {
    Box,
    Grid,
    Icon,
    SimpleGrid,
    useColorModeValue,
  } from "@chakra-ui/react";
  // Custom components
  import Card from "../../../components/card/Card";
  import MiniStatistics from "../../../components/card/MiniStatistics";
  import IconBox from "../../../components/icons/IconBox";
  import React from "react";
  // Assets
  import {
    MdAttachMoney,
    MdCreditCard,
    MdPerson,
    MdRedo,
    MdShowChart,
  } from "react-icons/md";
  import Earn from "../../../views/dashboard/referrals/components/Earn";
  import Invite from "../../../views/dashboard/referrals/components/Invite";
  import ReferralsTable from "../../../views/dashboard/referrals/components/ReferralsTable";
  import { columnsDataReferrals } from "../../../views/dashboard/referrals/variables/columnsDataReferrals";
  import tableDataReferrals from "../../../views/dashboard/referrals/variables/tableDataReferrals.json";
  
  export default function Referrals() {
    // Chakra Color Mode
    const brandColor = useColorModeValue("brand.500", "white");
    const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Grid w='100%' mb='20px' gap='20px' templateColumns='2.1fr 1fr'>
          <Earn
            me='20px'
            gridArea={{ base: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}
          />
          <Invite
            gridArea={{ base: "2 / 1 / 3 / 3", "2xl": "1 / 2 / 2 / 3" }}
          />
        </Grid>
        <SimpleGrid columns={{ base: 1, md: 2, xl: 5 }} gap='20px' mb='20px'>

          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={<Icon w='32px' h='32px' as={MdPerson} color={brandColor} />}
              />
            }
            name='Referrals'
            value='5'
          />
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={<Icon w='32px' h='32px' as={MdPerson} color={brandColor} />}
              />
            }
            name='Verified Users'
            value='5'
          />
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />
                }
              />
            }
            name='Your Earnings'
            value='$3,680'
          />
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={MdShowChart} color={brandColor} />
                }
              />
            }
            name='CCT tokens sold'
            value='3,500,000'
          />
        </SimpleGrid>
      </Box>
    );
  }
  