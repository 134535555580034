// Chakra imports
import { Flex, Box, Icon, Text, Spacer } from "@chakra-ui/react";
// Custom components
import Card from "../card/Card.js";

// Assets
import bgMastercard from "../../assets/img/promo.png";
import { RiMastercardFill } from "react-icons/ri";

export default function Banner(props) {
  const { exp, cvv, number, ...rest } = props;

  // Chakra Color Mode
  return (
    <Card
      borderRadius='20px'
      backgroundImage={bgMastercard}
      backgroundRepeat='no-repeat'
      bgSize='cover'
      boxShadow='0px 32px 41px -18px rgba(242, 134, 134, 0.4)'
      alignSelf='center'
      w={{ base: "100%", md: "60%", xl: "99%" }}
      bgPosition='10%'
      mx='auto'
      p='20px'
      {...rest}>
      <Flex direction='column' color='white' h='100%' w='100%'>
        <Flex justify='space-between' align='center' mb='6px'>
          <Text fontSize='xl' fontWeight='bold'>
            Get 100% bonus
          </Text>
        </Flex>
        <Flex justify='space-between' align='center' mb='64px'>
          <Text fontSize='xl' fontWeight='bold'>
            in CCT tokens
          </Text>
        </Flex>
        <Flex direction='column'>
          <Text fontSize='xs'>During the promotional </Text>
          <Text fontSize='xs'>period we will match </Text>
          <Text fontSize='xs'>any amount of top up </Text>
          <Text fontSize='xs'>and credit your account </Text>
          <Text fontSize='xs'>with Clash Chain Tokens </Text>
          <Text fontSize='xs'>Hurry, only 1.000.000,000 </Text>
          <Text fontSize='xs'>CCT available. </Text>
        </Flex>
      </Flex>
    </Card>
  );
}
