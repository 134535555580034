import { useState } from "react";

// Chakra imports
import {
  Flex,
  Box,
  Button,
  IconButton,
  Icon,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useColorModeValue,
} from "@chakra-ui/react";

import { useNavigate } from "react-router-dom";
// Custom components
import Card from "../../../../../components/card/Card.js";
import PlayPromo from "../../../../../components/promo/PlayPromo.js";

// Assets
import {
  MdCached,
  MdAdd,
  MdAttachMoney,
  MdMoreHoriz,
} from "react-icons/md";

export default function YourCard(props) {
  const { ...rest } = props;

  const navigate = useNavigate();

  // Chakra Color Mode
  const iconColor = useColorModeValue("brand.500", "white");
  const greenIcon = useColorModeValue("green.500", "white");
  const redIcon = useColorModeValue("red.500", "white");
  const yellowIcon = useColorModeValue("yellow.500", "white");
  const bgIconButton = useColorModeValue("white", "whiteAlpha.100");
  const bgIconHover = useColorModeValue(
    { bg: "gray.100" },
    { bg: "whiteAlpha.50" }
  );
  const bgIconFocus = useColorModeValue(
    { bg: "grey.100" },
    { bg: "whiteAlpha.100" }
  );
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const shadow = useColorModeValue(
    "18px 17px 40px 4px rgba(112, 144, 176, 0.1)",
    "unset"
  );


  const bgIconBrandButton = useColorModeValue("brand.500", "brand.400");
  const bgIconBrandButtonHover = useColorModeValue(
    { bg: "brand.100" },
    { bg: "brand.50" }
  );
  const bgIconBrandButtonFocus = useColorModeValue(
    { bg: "brand.100" },
    { bg: "brand.100" }
  );
  const textColor = useColorModeValue("secondaryGray.900", "white");
  return (
    <Card {...rest}>
      <Flex justify='space-between' px='10px' pt='5px' mb='25px' align='center'>
        <Text
          color={textColor}
          fontSize='lg'
          fontWeight='700'
          lineHeight='100%'>
          Play our games
        </Text>
      </Flex>
      <Tabs>
        <TabPanels mb='20px'>
          <PlayPromo />
        </TabPanels>
      </Tabs>
      <Flex
        direction='column'
        bg={boxBg}
        p='16px 20px'
        borderRadius='14px'
        mb='38px'>
        <Text fontSize='sm' fontWeight='700' color={textColor}>
          Coin Clash Games promotion
        </Text>
        <Text fontSize='sm' fontWeight='500' color='secondaryGray.600'>
          During the promotion period, Coin Clash games will top up your account with 100% of the amount you spend on toping up your Coin Clash Games account with promotional Clash Chain Tokens.
        </Text>
      </Flex>
      <Flex justifyContent='flex-end' mb='0px'>
        <Flex
          direction='column'
          align='center'
          me={{ base: "22px", md: "40px", "2xl": "36px" }}>
          <IconButton
            borderRadius='50%'
            bg={bgIconBrandButton}
            _hover={bgIconBrandButtonHover}
            _active={bgIconBrandButtonFocus}
            _focus={bgIconBrandButtonFocus}
            w='56px'
            h='56px'
            mb='5px'
            boxShadow={shadow}
            icon={<Icon as={MdAdd} color={yellowIcon} w='24px' h='24px' />}
            onClick={() => navigate('/admin/topup')}
          />
          <Text fontSize='sm' fontWeight='500' color={textColor}>
            Top Up
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
}
