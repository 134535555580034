import { useState, useEffect } from "react";

// Chakra imports
import {
  Flex,
  Button,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

// Custom components
import Card from "../../../../../components/card/Card.js";

// Assets
import balanceImg from "../../../../../assets/img/balanceImg.png";
import exchange from "../../../../../assets/img/exchange.svg";

import { conversionApi, conversionApiBTC, conversionApiBNB, bitclashToUstc, formatNumber} from '../../../../../_helpers/Conversions';
import { ApiHeader } from '../../../../../_helpers/ApiHeader';
import { Logout } from '../../../../../_helpers/Auth';

import { useNavigate } from "react-router-dom";

export default function BuyTokens(props) {
  const { ...rest } = props;

  const navigate = useNavigate();
  
  const [userCoins, setUserCoins] = useState(false);

  const [BTCrate, setBTCrate] = useState(0)
  const [BNBRate, setBNBRate] = useState(0)
  const [ETHrate, setETHrate] = useState(0)
  const [bitClash, setBitClash] = useState(0)

  useEffect(()=>{
    getTokenData();

    setBitClash(1 / bitclashToUstc(1))
    conversionApi(setETHrate)
    conversionApiBTC(setBTCrate)
    conversionApiBNB(setBNBRate)
  },[])

  const getTokenData = () => {

    fetch(process.env.REACT_APP_API_URL+'api/user/usercoinsdata', {
      method: 'GET',
      headers: ApiHeader('auth')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      console.log(data)

      if (data.status === 1) {
        setUserCoins(data.clashcoin)
      } else if (data.status === 401) {
        console.log('401')
        console.log(data.message)
        Logout();
      }
      
    })
    .catch(err => {
        console.log("fetch error: " + err);
    });
  }

  // Chakra Color Mode
  const balanceBg = useColorModeValue("brand.900", "#1B254B");
  
  return (
    <Card direction='column' w='100%' {...rest}>
      <Flex
        justify='space-between'
        p='20px'
        mb='20px'
        borderRadius='16px'
        bgColor={balanceBg}
        bgImage={balanceImg}
        bgPosition='right'
        bgSize='cover'>
        <Flex align='center' justify='space-between' w='100%'>
          <Flex flexDirection='column' me='20px'>
            <Text color='white' fontSize='sm' fontWeight='500'>
              Your Token Balance
            </Text>
            <Text
              color='white'
              fontSize='34px'
              fontWeight='700'
              lineHeight='100%'>
              {formatNumber(userCoins)}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Button variant='brand' borderRadius='70px' maxW='128px' ms='auto' onClick={() => navigate('/admin/buy-cct')}>
        Buy more
      </Button>
      <Text color='secondaryGray.600' fontWeight='500' fontSize='sm' mb='10px'>
        Token price
      </Text>
      <Flex direction='column'>
        <Flex flexDirection='row' me='20px' mb='10px' justify='space-between' w='100%'>
            <Text color='white' fontSize='sm' fontWeight='500'>
              1 USDT
            </Text>
            <Image 
                boxSize='15px'
                src={exchange} 
                alt='Exchange' />
            <Text color='white' fontSize='sm' fontWeight='500'>
              {formatNumber(bitClash)} CCT
            </Text>
        </Flex>
        <Flex flexDirection='row' me='20px' mb='10px' justify='space-between' w='100%'>
            <Text color='white' fontSize='sm' fontWeight='500'>
              1 ETH
            </Text>
            <Image 
                boxSize='15px'
                src={exchange} 
                alt='Exchange' />
            <Text color='white' fontSize='sm' fontWeight='500'>
              {formatNumber(bitClash * ETHrate)} CCT
            </Text>
        </Flex>
        <Flex flexDirection='row' me='20px' mb='10px' justify='space-between' w='100%'>
            <Text color='white' fontSize='sm' fontWeight='500'>
              1 BTC
            </Text>
            <Image 
                boxSize='15px'
                src={exchange} 
                alt='Exchange' />
            <Text color='white' fontSize='sm' fontWeight='500'>
              {formatNumber(bitClash * BTCrate)} CCT
            </Text>
        </Flex>
        <Flex flexDirection='row' me='20px' justify='space-between' w='100%'>
            <Text color='white' fontSize='sm' fontWeight='500'>
              1 BNB
            </Text>
            <Image 
                boxSize='15px'
                src={exchange} 
                alt='Exchange' />
            <Text color='white' fontSize='sm' fontWeight='500'>
              {formatNumber(bitClash * BNBRate)} CCT
            </Text>
        </Flex>
      </Flex>
    </Card>
  );
}
