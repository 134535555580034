import { JWTToken, IsValid } from "../_helpers/Auth";

const ApiHeader = (type) => {

    if (type === 'auth') {

        if (IsValid()) {
            return { 
                'Authorization': 'Bearer ' + JWTToken(),
                'Content-Type': 'application/json'
            };
        } else {
            console.log("no autthData");
            return { 'Content-Type': 'application/json' };
        }
    } else {
        return { 
            'Authorization': 'Basic ' + process.env.REACT_APP_BASIC_AUTH,
            'Content-Type': 'application/json'
        };
    }
}

export {ApiHeader};