
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Select,
  SimpleGrid,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
  useColorModeValue,
  Spinner,
} from "@chakra-ui/react";

// Custom components
import Card from "../../../components/card/Card";
import InputField from "../../../components/fields/InputField";
import TextField from "../../../components/fields/TextField";
import TagsField from "../../../components/fields/TagsField";

import { Logout } from "../../../_helpers/Auth";

import { ApiHeader } from '../../../_helpers/ApiHeader';

import Buybutton from "../../../views/dashboard/topup/components/Buybutton";
import Chainbutton from "../../../views/dashboard/topup/components/Chainbutton";
import Tokenbutton from "../../../views/dashboard/topup/components/Tokenbutton";

import QRCode from "react-qr-code";
import { ethers } from 'ethers';

import { formatUS, conversionApi, conversionApiMATIC, conversionApiBNB, amountToCCT, amountToEthTopUp, amountToBNBTopUp, amountToMaticTopUp, formatNumberNoDigits} from '../../../_helpers/Conversions';

import{ useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function TopupCoinClashGames() {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [activeBullets, setActiveBullets] = useState({
    selectamount: true,
    selectblockchain: false,
    purchase: false,
    confirmation: false,
    complete: false,
  });

  const toast = useToast();
  const navigate = useNavigate();

  const [amount, setAmount] = useState(25);
  const [chain, setChain] = useState('');
  const [token, setToken] = useState('');

  const [transactionHash, setTransactionHash] = useState('');
  const [transactionStatus, setTransactionStatus] = useState('');

  const QRcodeSize = 128;
  const saleAddress = '0xba2a901c702d1b691cb0d3fa8875e018af6053ce';
  // const saleAddress = '0x182cE7fe556624dFf75fd2563678ac1c642f629E'; // DEV ADDRESS

  const USDTonETH = '0xdac17f958d2ee523a2206206994597c13d831ec7';
  const USDConETH = '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48';

  const USDTonPOLYGON = '0xc2132D05D31c914a87C6611C10748AEb04B58e8F';
  const USDConPOLYGON = '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174';

  const amountTab = useRef();
  const blockchainTab = useRef();
  const purchaseTab = useRef();
  const confirmationTab = useRef();
  const completedTab = useRef();

  const brand = useColorModeValue("brand.500", "brand.400");
  const coinsColor = useColorModeValue("brandgold.500", "brandgold.100");

  const iconColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const tokens = new Map();
  tokens.set('', ['', '', '']);
  tokens.set('Ethereum', ['ETH', 'USDC', 'USDT']);
  tokens.set('Polygon', ['MATIC', 'USDC', 'USDT']);
  // tokens.set('BSC', ['BNB', '', '']);
  tokens.set('Arbitrum', ['ETH', '', '']);

  const chainid = new Map();
  chainid.set('', '');
  chainid.set('Ethereum', '0x1');
  chainid.set('Polygon', '0x89');
  chainid.set('BSC', '0x38');
  chainid.set('Arbitrum', '0xa4b1');


  const [MATICrate, setMATICrate] = useState(0)
  const [BNBRate, setBNBRate] = useState(0)
  const [ETHrate, setETHrate] = useState(0)

  useEffect(()=>{
    conversionApi(setETHrate)
    conversionApiMATIC(setMATICrate)
    conversionApiBNB(setBNBRate)
  },[])

  const resetChain = (chain) => {
    setChain(chain);
    setToken('');
  }

  const getPrice = () => {
    if (token === 'ETH') {
      return amountToEthTopUp(amount, ETHrate);
    } else if (token === 'BNB') {
      return amountToBNBTopUp(amount, BNBRate);
    } else if (token === 'MATIC') {
      return amountToMaticTopUp(amount, MATICrate);
    } else {
      return formatUS(amount);
    }
  }

  const buyMetamask = async () => {
    console.log("buy metamask");

    if(!(window.ethereum)){
      toast({
        title: 'Error.',
        description: "No WEB3 wallet found in your browser.",
        status: 'error',
      })
      return;
    }
    try {
      const accounts = await window.ethereum.request({ method: "eth_accounts" });
      (accounts.length === 0 && await window.ethereum.request({ method: "eth_requestAccounts" }));
    //   switch network to Rinkeby

      if(window.ethereum.chainId !== chainid.get(chain)) {
        toast({
          title: 'Error.',
          description: "switching to "+chain+" network...",
          status: 'error',
        })
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: chainid.get(chain) }],  // 0x4 is the id of the Rinkeby chain //change it in the final build
        });
      }
      
      const provider = new ethers.BrowserProvider(window.ethereum)
      const signer = await provider.getSigner();

      provider.send("eth_requestAccounts", []).then(async () => {
        const address = await signer.getAddress()

        const transferAmount = getPrice(amount); // changeLIVE, not divide by 1000

        console.log(address)
        console.log("transferAmount: " + transferAmount)

        let transaction;

        if (token === 'USDC' || token === 'USDT') {
          let contractAddress = '';

          if (token === 'USDT' && chain === 'Ethereum') {
            contractAddress = USDTonETH;
          }

          if (token === 'USDC' && chain === 'Ethereum') {
            contractAddress = USDConETH;
          }

          if (token === 'USDT' && chain === 'Polygon') {
            contractAddress = USDTonPOLYGON;
          }

          if (token === 'USDC' && chain === 'Polygon') {
            contractAddress = USDConPOLYGON;
          }

          if (contractAddress === '') {
            toast({
              title: 'Error.',
              description: "COin not supported. Please select another coin.",
              status: 'error',
            })
            return;
          }

          const coinContract = new ethers.Contract(contractAddress, [
            'function transfer(address to, uint256 amount) returns (bool)',
          ], signer);

          console.log(transferAmount)
          console.log(typeof transferAmount)

          const coinAmount = ethers.parseUnits(parseFloat(transferAmount).toFixed(6), 6);
          console.log("coinAmount: " + coinAmount)
          console.log("contractAddress: " + contractAddress)
          console.log("saleAddress: " + saleAddress)

          transaction = await coinContract.transfer(saleAddress, coinAmount);

          console.log("transaction: ")
          console.log(transaction);

          saveTxHash(transaction.hash, address, amount)

          setTransactionHash(transaction.hash);
          setTransactionStatus('Pending')

        } else {

          console.log(transferAmount)
          console.log(typeof transferAmount)

          transaction = await signer.sendTransaction({
            to:saleAddress, 
            value: ethers.parseEther(parseFloat(transferAmount).toFixed(17))
          });
              
          saveTxHash(transaction.hash, address, amount)
          
          setTransactionHash(transaction.hash);
          setTransactionStatus('Pending')
        }

        confirmationTab.current.click();

        await transaction.wait();

        setTransactionStatus('Verifying');

        checkTransactionStatus(transaction.hash)
      })
      .catch(e => {
        console.log(e)
        const error = e.toString();
        if(error.includes("insufficient funds")){
          toast({
            title: 'Error.',
            description: "Insufficient Funds",
            status: 'error',
          })
        }
        else if (error.includes("user rejected action")) {
          toast({
            title: 'Error.',
            description: "User declined the metamask request",
            status: 'error',
          })
        }
        else{
          toast({
            title: 'Error.',
            description: "Something went wrong",
            status: 'error',
          })
          console.log(e);
        } 

      });
              
    } catch (e) {
      if (e.code === 4001) {
          toast({
            title: 'Error.',
            description: "User declined the metamask request",
            status: 'error',
          })
      }
      else {
          const error = e.toString();
          if(error.includes("insufficient funds")){
            toast({
              title: 'Error.',
              description: "Insufficient Funds",
              status: 'error',
            })
          }
          else{
            toast({
              title: 'Error.',
              description: "Something went wron",
              status: 'error',
            })
            console.log(e);
          } 
      }
    }
  }

  const saveTxHash = (hash, address, amount) => {

    if (hash === '' || address === '' || amount === '') {
      return;
    }

    fetch(process.env.REACT_APP_API_URL+'api/user/transactionstartgames', {
      method: 'POST',
      body: JSON.stringify({
        "txhash" : hash,
        "address" : address,
        "amount" : amount
      }),
      headers: ApiHeader('auth')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      console.log(data)
      if (data.status === 1) {
        // toast({
        //   title: 'Email verified',
        //   description: "Thank you for verifying your email",
        //   status: 'success',
        // })
        console.log("hash saved")
      } else if (data.status === 401) {
        console.log('401')
        console.log(data.message)
        Logout();
      } else {
        console.log(data.message)
        toast({
            title: 'Error.',
            description: data.message,
            status: 'error',
          })
      }
    })
    .catch(err => {
      toast({
          title: 'Error.',
          description: "Error, please try again",
          status: 'error',
        })
        console.log("fetch error: " + err);
    });
  }

  const viewOnExplorer = () => {
    if (transactionHash) {
      const etherscanLink = `https://etherscan.io/tx/${transactionHash}`;
      const polygonscanLink = `https://polygonscan.com/tx/${transactionHash}`;
      const bscscanLink = `https://bscscan.com/tx/${transactionHash}`;

      if (chain === 'Ethereum') {
        window.open(etherscanLink, '_blank');
      }
      if (chain === 'Polygon') {
        window.open(polygonscanLink, '_blank');
      }
      if (chain === 'BSC') { 
        window.open(bscscanLink, '_blank');
      }
    }
  }

  const checkTransactionStatus = async (hash) => {
    fetch(process.env.REACT_APP_API_URL+'api/user/verifyhashccg', {
      method: 'POST',
      body: JSON.stringify({
        "txhash" : hash.toLowerCase(),
        "amount" : amount,
        "chain" : chain.toUpperCase(),
        "stablecoin" : token.includes('USD')
      }),
      headers: ApiHeader('auth')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      console.log(data)
      if (data.status === 1) {
        console.log("verified");
        completedTab.current.click();
      } else if (data.status === 401) {
        console.log('401')
        console.log(data.message)
        Logout();
      } else {
        console.log(data.message)
        toast({
          title: 'Error.',
          description: data.message,
          status: 'error',
        })
      }
    })
    .catch(err => {
      toast({
          title: 'Error.',
          description: "Error, please try again",
          status: 'error',
        })
        console.log("fetch error: " + err);
    })
    .finally(() => {
      setTransactionStatus('');
    });
  }

  return (
    <Flex
      direction='column'
      minH='100vh'
      align='center'
      pt={{ sm: "125px", lg: "75px" }}
      position='relative'>
      <Box
        h='45vh'
        bg='linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'
        position='absolute'
        w='100%'
        borderRadius='20px'></Box>

      <Tabs
        variant='unstyled'
        mt={{ base: "60px", md: "65px" }}
        zIndex='0'
        display='flex'
        flexDirection='column'>
        <TabList
          display='flex'
          align='center'
          alignSelf='center'
          justifySelf='center'>
          <Tab
            ref={amountTab}
            _focus={{}}
            w={{ sm: "60px", md: "120px", lg: "160px" }}
            onClick={() =>
              setActiveBullets({
                selectamount: true,
                selectblockchain: false,
                purchase: false,
                confirmation: false,
                complete: false,
              })
            }>
            <Flex
              direction='column'
              justify='center'
              align='center'
              position='relative'
              _before={{
                content: "''",
                width: { sm: "60px", md: "120px", lg: "160px" },
                height: "3px",
                bg: activeBullets.selectblockchain ? "white" : "#8476FF",
                left: { sm: "12px", md: "40px" },
                top: {
                  sm: activeBullets.selectamount ? "6px" : "4px",
                  md: null,
                },
                position: "absolute",
                bottom: activeBullets.selectamount ? "40px" : "38px",

                transition: "all .3s ease",
              }}>
              <Box
                zIndex='1'
                border='2px solid'
                borderColor={activeBullets.selectamount ? "white" : "#8476FF"}
                bg='linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'
                w='16px'
                h='16px'
                mb='8px'
                borderRadius='50%'
              />
              <Text
                color={activeBullets.selectamount ? "white" : "gray.300"}
                fontWeight={activeBullets.selectamount ? "bold" : "normal"}
                display={{ sm: "none", md: "block" }}>
                Amount
              </Text>
            </Flex>
          </Tab>
          <Tab
            ref={blockchainTab}
            _focus={{}}
            w={{ sm: "60px", md: "120px", lg: "160px" }}
            onClick={() =>
              setActiveBullets({
                selectamount: true,
                selectblockchain: true,
                purchase: false,
                confirmation: false,
                complete: false,
              })
            }>
            <Flex
              direction='column'
              justify='center'
              align='center'
              position='relative'
              _before={{
                content: "''",
                width: { sm: "60px", md: "120px", lg: "160px" },
                height: "3px",
                bg: activeBullets.purchase ? "white" : "#8476FF",
                left: { sm: "12px", md: "28px" },
                top: "6px",
                position: "absolute",
                bottom: activeBullets.selectblockchain ? "40px" : "38px",

                transition: "all .3s ease",
              }}>
              <Box
                zIndex='1'
                border='2px solid'
                borderColor={activeBullets.selectblockchain ? "white" : "#8476FF"}
                bg='linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'
                w='16px'
                h='16px'
                mb='8px'
                borderRadius='50%'
              />
              <Text
                color={activeBullets.selectblockchain ? "white" : "gray.300"}
                fontWeight={activeBullets.selectblockchain ? "bold" : "normal"}
                display={{ sm: "none", md: "block" }}>
                Blockchain
              </Text>
            </Flex>
          </Tab>
          <Tab
            ref={purchaseTab}
            _focus={{}}
            w={{ sm: "60px", md: "120px", lg: "160px" }}
            onClick={() =>
              setActiveBullets({
                selectamount: true,
                selectblockchain: true,
                purchase: true,
                confirmation: false,
                complete: false,
              })
            }>
            <Flex
              direction='column'
              justify='center'
              align='center'
              position='relative'
              _before={{
                content: "''",
                width: { sm: "60px", md: "120px", lg: "160px" },
                height: "3px",
                bg: activeBullets.confirmation ? "white" : "#8476FF",
                left: { sm: "12px", md: "40px" },
                top: {
                  sm: activeBullets.purchase ? "6px" : "4px",
                  md: null,
                },
                position: "absolute",
                bottom: activeBullets.purchase ? "40px" : "38px",

                transition: "all .3s ease",
              }}>
              <Box
                zIndex='1'
                border='2px solid'
                borderColor={activeBullets.purchase ? "white" : "#8476FF"}
                bg='linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'
                w='16px'
                h='16px'
                mb='8px'
                borderRadius='50%'
              />
              <Text
                color={activeBullets.purchase ? "white" : "gray.300"}
                fontWeight={activeBullets.purchase ? "bold" : "normal"}
                display={{ sm: "none", md: "block" }}>
                Purchase
              </Text>
            </Flex>
          </Tab>
          <Tab
            ref={confirmationTab}
            _focus={{}}
            w={{ sm: "60px", md: "120px", lg: "160px" }}
            onClick={() =>
              setActiveBullets({
                selectamount: true,
                selectblockchain: true,
                purchase: true,
                confirmation: true,
                complete: false,
              })
            }>
            <Flex
              direction='column'
              justify='center'
              align='center'
              position='relative'
              _before={{
                content: "''",
                width: { sm: "60px", md: "120px", lg: "160px" },
                height: "3px",
                bg: activeBullets.complete ? "white" : "#8476FF",
                left: { sm: "12px", md: "40px" },
                top: {
                  sm: activeBullets.confirmation ? "6px" : "4px",
                  md: null,
                },
                position: "absolute",
                bottom: activeBullets.confirmation ? "40px" : "38px",

                transition: "all .3s ease",
              }}
              >
              <Box
                zIndex='1'
                border='2px solid'
                borderColor={activeBullets.confirmation ? "white" : "#8476FF"}
                bg='linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'
                w='16px'
                h='16px'
                mb='8px'
                borderRadius='50%'
              />
              <Text
                color={activeBullets.confirmation ? "white" : "gray.300"}
                fontWeight={activeBullets.confirmation ? "bold" : "normal"}
                display={{ sm: "none", md: "block" }}>
                Confirmation
              </Text>
            </Flex>
          </Tab>
          <Tab
            ref={completedTab}
            _focus={{}}
            w={{ sm: "60px", md: "120px", lg: "160px" }}
            onClick={() =>
              setActiveBullets({
                selectamount: true,
                selectblockchain: true,
                purchase: true,
                confirmation: true,
                complete: true,
              })
            }>
            <Flex
              direction='column'
              justify='center'
              align='center'
              position='relative'>
              <Box
                zIndex='1'
                border='2px solid'
                borderColor={activeBullets.complete ? "white" : "#8476FF"}
                bg='linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'
                w='16px'
                h='16px'
                mb='8px'
                borderRadius='50%'
              />
              <Text
                color={activeBullets.complete ? "white" : "gray.300"}
                fontWeight={activeBullets.complete ? "bold" : "normal"}
                display={{ sm: "none", md: "block" }}>
                Completed
              </Text>
            </Flex>
          </Tab>
        </TabList>
        <TabPanels mt='24px' maxW={{ md: "90%", lg: "100%" }} mx='auto'>
          <TabPanel
            w={{ sm: "330px", md: "700px", lg: "850px" }}
            p='0px'
            mx='auto'>
            <Card p='30px'>
              <Text color={textColor} fontSize='2xl' fontWeight='700' mb='20px'>
                How much do you want to top up?
              </Text>
              <Flex direction='column' w='100%'>
                <Stack direction='column' gap='10px'>
                  <SimpleGrid columns={{ base: "1", md: "3" }} gap='20px'>
                    <Buybutton 
                      amount={amount}
                      setAmount={setAmount}
                      buttonAmount={5} />
                    <Buybutton 
                      amount={amount}
                      setAmount={setAmount}
                      buttonAmount={10} />
                    <Buybutton 
                      amount={amount}
                      setAmount={setAmount}
                      buttonAmount={25} />
                  </SimpleGrid>
                  <SimpleGrid columns={{ base: "1", md: "3" }} gap='20px'>
                    <Buybutton 
                      amount={amount}
                      setAmount={setAmount}
                      buttonAmount={50} />
                    <Buybutton 
                      amount={amount}
                      setAmount={setAmount}
                      buttonAmount={75} />
                    <Buybutton 
                      amount={amount}
                      setAmount={setAmount}
                      buttonAmount={100} />
                  </SimpleGrid>
                </Stack>
                <Flex justify='space-between' mt='24px'>
                  <Button
                    variant='darkBrand'
                    fontSize='sm'
                    borderRadius='16px'
                    w={{ base: "128px", md: "148px" }}
                    h='46px'
                    ms='auto'
                    onClick={() => blockchainTab.current.click()}>
                    Next
                  </Button>
                </Flex>
              </Flex>
            </Card>
          </TabPanel>
          <TabPanel
            w={{ sm: "330px", md: "700px", lg: "850px" }}
            p='0px'
            mx='auto'>
            <Card p='30px'>
              <SimpleGrid columns={{ base: "1", md: "3" }} gap='20px'>
                <Stack direction='column' gap='10px'>
                  <Text color={textColor} fontSize='2xl' fontWeight='700' mb='20px'>
                    Select blockchain
                  </Text>
                  <Chainbutton 
                    chain={chain}
                    setChain={resetChain}
                    buttonChain={'Ethereum'} />
                  <Chainbutton 
                    chain={chain}
                    setChain={resetChain}
                    buttonChain={'Polygon'} />
                  {/* <Chainbutton 
                    chain={chain}
                    setChain={resetChain}
                    buttonChain={'BSC'} /> */}
                  {/* <Chainbutton 
                    chain={chain}
                    setChain={resetChain}
                    buttonChain={'Arbitrum'} /> */}
                </Stack>
                <Stack direction='column' gap='10px'>
                  <Text color={textColor} fontSize='2xl' fontWeight='700' mb='20px'>
                    Select token
                  </Text>
                  <Tokenbutton 
                    chain={chain}
                    token={token}
                    setToken={setToken}
                    buttonToken={tokens.get(chain)[0]} />
                  <Tokenbutton 
                    chain={chain}
                    token={token}
                    setToken={setToken}
                    buttonToken={tokens.get(chain)[1]} />
                  <Tokenbutton 
                    chain={chain}
                    token={token}
                    setToken={setToken}
                    buttonToken={tokens.get(chain)[2]} />
                </Stack>
                <Stack direction='column' gap='10px'>
                  <Text color={textColor} fontSize='2xl' fontWeight='700' mb='20px'>
                    Overview
                  </Text>
                  <Text textAlign='center' color={textColor}>
                    Topup amount:
                  </Text>
                  <Text textAlign='center' color={textColor} fontSize='2xl' fontWeight='700' mb='20px'>
                    ${formatUS(amount)}
                  </Text>
                  <Text textAlign='center' color={textColor} display={token===''?'none':'block'}>
                    Price to pay:
                  </Text>
                  <Text textAlign='center' color={coinsColor} fontSize='2xl' fontWeight='700' mb='20px'  display={token===''?'none':'block'}>
                    {getPrice(amount)} {token}
                  </Text>
                </Stack>
              </SimpleGrid>
              <Flex justify='space-between' mt='24px'>
                <Button
                  variant='light'
                  fontSize='sm'
                  borderRadius='16px'
                  w={{ base: "128px", md: "148px" }}
                  h='46px'
                  onClick={() => amountTab.current.click()}>
                  Prev
                </Button>
                {(chain!==''&&token!=='') && (
                  <Button
                    variant='darkBrand'
                    fontSize='sm'
                    borderRadius='16px'
                    w={{ base: "128px", md: "148px" }}
                    h='46px'
                    onClick={() => purchaseTab.current.click()}>
                    Next
                  </Button>
                )}
              </Flex>
            </Card>
          </TabPanel>
          <TabPanel
            w={{ sm: "330px", md: "700px", lg: "850px" }}
            p='0px'
            mx='auto'>
            <Card p='30px'>
              <Text color={textColor} fontSize='2xl' fontWeight='700' mb='20px'>
                Purchase
              </Text>
              <SimpleGrid columns={{ base: "1", md: "2" }} gap='20px'>
                <Stack direction='column' gap='10px'>
                  <Text textAlign='center' color={textColor}>
                    Topup amount:
                  </Text>
                  <Text textAlign='center' color={textColor} fontSize='2xl' fontWeight='700' mb='20px'>
                    ${formatUS(amount)}
                  </Text>
                  <Text textAlign='center' color={textColor} display={token===''?'none':'block'}>
                    Price to pay:
                  </Text>
                  <Text textAlign='center' color={coinsColor} fontSize='2xl' fontWeight='700' mb='20px' display={token===''?'none':'block'}>
                    {getPrice(amount)} {token}
                  </Text>
                  <Text textAlign='center' color={textColor} display={token===''?'none':'block'}>
                    Blockchain to use:
                  </Text>
                  <Text textAlign='center' color={coinsColor} fontSize='2xl' fontWeight='700' mb='20px' display={token===''?'none':'block'}>
                    {chain}
                  </Text>
                  <Text textAlign='center' color={textColor}>
                    Please make the transfer to the following address:
                  </Text>
                  <Text textAlign='center' color={textColor} fontWeight='700' mb='20px'>
                    {saleAddress}
                  </Text>
                </Stack>
                <Stack direction='column' gap='10px'>
                  <Button
                      variant='darkBrand'
                      fontSize='sm'
                      margin="auto"
                      borderRadius='16px'
                      w={{ base: "168px", md: "188px" }}
                      h='46px'
                      mb='20px'
                      onClick={buyMetamask}>
                      Buy with metamask
                    </Button>
                  <Text textAlign='center' color={textColor}>
                    If you want to buy tokens from a wallet not connected to your browser or an exchange, you can:
                  </Text>
                  <Button
                    variant='light'
                    margin="auto"
                    fontSize='sm'
                    borderRadius='16px'
                    w={{ base: "128px", md: "148px" }}
                    h='46px'
                    onClick={function () {
                      navigator.clipboard.writeText(saleAddress);
                      toast({
                        title: `Address copied!`,
                        position: "top",
                        status: "success",
                        isClosable: true,
                      });
                    }}>
                    Copy address
                  </Button>
                  <Text textAlign='center' color={textColor} display={token===''?'none':'block'}>
                    or scan the code:
                  </Text>
                  <Box bg='white' 
                    margin="auto"
                    w='158px' 
                    p={4} 
                    mb='20px'
                    color='white'>
                    <QRCode size={QRcodeSize} value={saleAddress} />
                  </Box>
                </Stack>
              </SimpleGrid>
              <Flex direction="column" w='100%'>
                <Flex justify='space-between' direction={{ base: "column", md: "row" }} gap='10px' wrap="wrap" mt='24px'>
                  <Button
                    variant='light'
                    fontSize='sm'
                    borderRadius='16px'
                    w={{ base: "128px", md: "148px" }}
                    h='46px'
                    onClick={() => blockchainTab.current.click()}>
                    Prev
                  </Button>
                  <Stack direction='row' gap='10px'>
                    {/* <Button
                      variant='darkBrand'
                      fontSize='sm'
                      borderRadius='16px'
                      w={{ base: "128px", md: "148px" }}
                      h='46px'
                      onClick={() => confirmationTab.current.click()}>
                      Buy Now
                    </Button> */}
                    <Button
                      variant='darkBrand'
                      fontSize='sm'
                      borderRadius='16px'
                      w={{ base: "128px", md: "148px" }}
                      h='46px'
                      onClick={() => confirmationTab.current.click()}>
                      Already purchased
                    </Button>
                  </Stack>
                </Flex>
              </Flex>
            </Card>
          </TabPanel>
          <TabPanel
            w={{ sm: "330px", md: "700px", lg: "850px" }}
            p='0px'
            mx='auto'>
            <Card p='30px'>
              <Text color={textColor} fontSize='2xl' fontWeight='700' mb='20px'>
                Confirmation
              </Text>
              <Stack direction='column' gap='10px'>
                <Text textAlign='center' color={textColor}>
                  Transaction hash:
                </Text>
                <InputField
                  mb='20px'
                  me='30px'
                  id='transactionhash'
                  placeholder='transaction hash'
                  value={transactionHash}
                  onChange={(e) => {
                    setTransactionHash(e.target.value)

                    if (e.target.value.length === 0) {
                      setTransactionStatus('')
                    }
                  }}
                />

                <Text textAlign='center' color={textColor} mb="20px">
                  Transaction status: {transactionStatus}
                </Text>
                {(transactionStatus === 'Pending' || transactionStatus === 'Checking') && (
                  <Spinner
                    margin="auto"
                    mb="20px"
                  />
                )}
                <Flex justify='center' gap='20px'>
                  {transactionStatus === '' && (
                    <Button
                      variant='darkBrand'
                      fontSize='sm'
                      borderRadius='16px'
                      w={{ base: "128px", md: "148px" }}
                      h='46px'
                      onClick={() => {
                        if (transactionHash) {
                          setTransactionStatus('Checking')
                          checkTransactionStatus(transactionHash)
                        }
                      }}>
                      Check transation
                    </Button>
                  )}
                  <Button
                    variant='light'
                    fontSize='sm'
                    borderRadius='16px'
                    w={{ base: "128px", md: "148px" }}
                    h='46px'
                    onClick={() => viewOnExplorer()}>
                    View on explorer
                  </Button>
                </Flex>
              </Stack>
              <Flex direction='column' w='100%'>
                <Flex justify='space-between' mt='24px'>
                  <Button
                    variant='light'
                    fontSize='sm'
                    borderRadius='16px'
                    w={{ base: "128px", md: "148px" }}
                    h='46px'
                    onClick={() => purchaseTab.current.click()}>
                    Prev
                  </Button>
                </Flex>
              </Flex>
            </Card>
          </TabPanel>
          <TabPanel
            w={{ sm: "330px", md: "700px", lg: "850px" }}
            p='0px'
            mx='auto'>
            <Card p='30px'>
              <Text color={textColor} fontSize='2xl' fontWeight='700' mb='20px'>
                Transaction has been verified!
              </Text>

              <SimpleGrid columns={{ base: "1", md: "2" }} gap='20px'>
                <Stack direction='column' gap='10px'>
                  <Text textAlign='center' color={textColor}>
                    Your game account has been credited with:
                  </Text>
                  <Text textAlign='center' color={coinsColor} fontSize='4xl' fontWeight='700' mb='20px'>
                    ${formatUS(amount)}
                  </Text>
                </Stack>
                <Stack direction='column' gap='10px'>
                  <Text textAlign='center' color={textColor}>
                    As part of the propmotion you also received:
                  </Text>
                  <Text textAlign='center' color={coinsColor} fontSize='4xl' fontWeight='700' mb='20px'>
                    {amountToCCT(amount)} CCT
                  </Text>
                </Stack>
              </SimpleGrid>
              <Text textAlign='center' color={textColor}>
                Thank you!
              </Text>
              <Flex direction='column' w='100%'>
                <Flex justify='right' mt='24px'>
                  <Button
                    variant='darkBrand'
                    fontSize='sm'
                    borderRadius='16px'
                    w={{ base: "128px", md: "148px" }}
                    h='46px'
                    onClick={() => navigate('/admin/home')}>
                    Finish
                  </Button>
                </Flex>
              </Flex>
            </Card>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}
