import axios from "axios"

export const bitclashRate = 0.1;
export const bitclashPrice = 0.1;

export const conversionApi = (setAmount) => {
    axios.get(`https://api.coinbase.com/v2/prices/ETH-USD/buy`)
        .then(res => {
            setAmount(res.data.data.amount)
        })
        .catch(err => console.log('error', err))
}

export const conversionApiBTC = (setAmount) => {
    axios.get(`https://api.coinbase.com/v2/prices/BTC-USD/buy`)
        .then(res => {
            setAmount(res.data.data.amount)
        })
        .catch(err => console.log('error', err))
}

export const conversionApiBNB = (setAmount) => {
    axios.get(`https://api.binance.com/api/v3/ticker/price?symbol=BNBUSDT`)
        .then(res => {
            setAmount(res.data.price)
        })
        .catch(err => console.log('error', err))
}

export const conversionApiMATIC = (setAmount) => {
    axios.get(`https://api.binance.com/api/v3/ticker/price?symbol=MATICUSDT`)
        .then(res => {
            setAmount(res.data.price)
        })
        .catch(err => console.log('error', err))
}

export const formatUS = (money) =>{
    return  money.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    })
  }

export const bitclashToUstc = (amount) =>{
    const convertedAmount = formatUS(amount * bitclashRate);
    return convertedAmount
}

export const formatNumber = (amount) =>{
    const convertedAmount = parseFloat(amount);
    return convertedAmount.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
}

export const bitclashToEth = (amount, ETHtoUSD) =>{
    const convertedAmount = amount  * bitclashRate / ETHtoUSD
    return convertedAmount.toLocaleString('en-US', { maximumFractionDigits: 4, minimumFractionDigits: 4 })
}

export const ethToBitClash = (amount, amountEth) =>{
    const convertedAmount = amount * amountEth / bitclashRate
    return convertedAmount.toFixed(2)
} 

export const bitclashToBTC = (amount, BTCtoUSD) =>{
    const convertedAmount = amount  * bitclashRate / BTCtoUSD
    return convertedAmount.toLocaleString('en-US', { maximumFractionDigits: 8, minimumFractionDigits: 8 })
}

export const bitclashToBNB = (amount, BNBtoUSD) =>{
    const convertedAmount = amount  * bitclashRate / BNBtoUSD
    return convertedAmount.toLocaleString('en-US', { maximumFractionDigits: 4, minimumFractionDigits: 4 })
}

export const bitclashToMatic = (amount, MATICtoUSD) =>{
    const convertedAmount = amount  * bitclashRate / MATICtoUSD
    return convertedAmount.toLocaleString('en-US', { maximumFractionDigits: 4, minimumFractionDigits: 4 })
}

export const formatNumberNoDigits = (amount) =>{
    const convertedAmount = parseFloat(amount);
    return convertedAmount.toLocaleString('en-US', { maximumFractionDigits: 0, minimumFractionDigits: 0 })
}

export const amountToEthTopUp = (amount, ETHtoUSD) =>{
    const convertedAmount = amount / ETHtoUSD * 1.01;
    return convertedAmount.toLocaleString('en-US', { maximumFractionDigits: 4, minimumFractionDigits: 4 })
}

export const amountToBNBTopUp = (amount, BNBtoUSD) =>{
    const convertedAmount = amount / BNBtoUSD * 1.01;
    return convertedAmount.toLocaleString('en-US', { maximumFractionDigits: 4, minimumFractionDigits: 4 })
}

export const amountToMaticTopUp = (amount, MATICtoUSD) =>{
    const convertedAmount = amount / MATICtoUSD * 1.01;
    return convertedAmount.toLocaleString('en-US', { maximumFractionDigits: 4, minimumFractionDigits: 4 })
}

export const amountToCCT = (amount) =>{
    const convertedAmount = amount / bitclashPrice 
    return convertedAmount.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
}