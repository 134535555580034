import { useState} from "react"
import { Outlet, Navigate } from 'react-router-dom';
import jwt_decode from "jwt-decode";

import { useIdleTimer } from 'react-idle-timer';

export const IsValid = () => {
  const token = localStorage.getItem('clashchain_auth');

  if (!token) return false;
  
  const user = jwt_decode(token);
  var d = new Date().getTime();

  if (!user) {
    localStorage.removeItem("clashchain_auth");
    return false;
  }
    
  if (user.exp*1000<d) {
    localStorage.removeItem("clashchain_auth");
    return false;
  }

  return true;
};

export const SetJwtAuth = (jwt) => {
  localStorage.setItem("clashchain_auth", jwt);
};


export const Logout = () => {

  localStorage.removeItem("clashchain_auth");
  window.location.href="/auth/login";
};

export const JWTToken = () => {
  return IsValid() ? localStorage.getItem('clashchain_auth') : "";
}

export const GetUserData = () => {
  if (IsValid()) {
    const token = localStorage.getItem('clashchain_auth');
    const user = jwt_decode(token);
    console.log(user)

    return {
      email: user.email,
      username: user.username,
      expiry: user.exp*1000
    }
  } else {
    return null;
  }
}

const handleOnIdle = event => {
  Logout();
}

// checkout after 15 minutes of inactivity
export const ProtectRoutes = () => {
  useIdleTimer({
    timeout: 1000 * 15 * 60,
    onIdle: handleOnIdle,
    debounce: 500
  })

  return IsValid() ? <Outlet/> : <Navigate to='/auth/login' exact />
};


export const SetCCGAuth = (jwt) => {
  localStorage.setItem("ccgames_auth", jwt);
};

export const GetCCGAuth = () => {
  return IsCCGValid() ? localStorage.getItem("ccgames_auth") : '';
};

export const IsCCGValid = () => {
  const token = localStorage.getItem('ccgames_auth');

  if (!token) return false;
  
  const user = jwt_decode(token);
  var d = new Date().getTime();

  if (!user) {
    localStorage.removeItem("ccgames_auth");
    return false;
  }
    
  if (user.exp*1000<d) {
    localStorage.removeItem("ccgames_auth");
    return false;
  }

  return true;
};