// Chakra imports
import { Button, Flex, Text, useColorModeValue } from "@chakra-ui/react";
// Assets
import React from "react";

import balanceImg from "../../../../assets/img/balanceImg.png";

import { conversionApi, conversionApiBTC, conversionApiBNB, bitclashToUstc, formatNumber, formatNumberNoDigits} from '../../../../_helpers/Conversions';

function Chainbutton(props) {
    const {
        chain,
        setChain,
        buttonChain,
        ...rest 
    } = props;

    const bg = useColorModeValue("brand.400", "brand.400");
    const hooverBg = useColorModeValue("#868CFF", "#868CFF");
    const borderColor = useColorModeValue("gray.300", "whiteAlpha.100");
    const textColor = useColorModeValue("black", "white");
    const coinsColor = useColorModeValue("brandgold.500", "brandgold.100");

    const balanceBg = useColorModeValue("gray.100", "#1B254B");
    
    return (
        <Flex
        justify='space-between'
        p='20px'
        mb='20px'
        borderRadius='16px'
        bgColor={chain===buttonChain?bg:balanceBg}
        _hover={{ bgColor: hooverBg }}
        cursor='pointer'
        bgImage={balanceImg}
        bgPosition='right'
        bgSize='cover'
        onClick={()=>setChain(buttonChain)}>
            <Flex align='center' justify='space-between' w='100%'>
                <Flex flexDirection='column' me='20px' w='100%'>
                    <Text
                        color={textColor}
                        fontSize='26px'
                        fontWeight='700'
                        textAlign='right'
                        mb='10px'
                        lineHeight='100%'>
                        {buttonChain}
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    );
}

export default Chainbutton;
