import { useState, useEffect } from "react";
// Chakra imports
import { Box, Flex, Text, Badge, LightMode } from "@chakra-ui/react";

import { formatNumber, formatUS} from '../../../_helpers/Conversions';
import { ApiHeader } from '../../../_helpers/ApiHeader';


export default function SidebarCard(props) {
  const bgColor = "linear-gradient(135deg, #868CFF 0%, #4318FF 100%)";

  const { route } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [userCoins, setUserCoins] = useState(false);
  const [userEarn, setUserEarn] = useState(false);

	useEffect(()=>{
    console.log(`Route changed: ${route}`)
    getEarnData();
  },[route])

  const getEarnData = () => {

    fetch(process.env.REACT_APP_API_URL+'api/user/usercoinsdata', {
      method: 'GET',
      headers: ApiHeader('auth')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      console.log(data)

      if (data.status === 1) {
        setUserCoins(data.clashcoin)
        setUserEarn(data.earn)
      }
      
    })
    .catch(err => {
        console.log("fetch error: " + err);
    });
  }

  return (
    <Flex
      justify='center'
      direction='column'
      align='center'
      bg={bgColor}
      borderRadius='20px'
      me='20px'
      position='relative'>
      <Flex
        direction='column'
        mb='12px'
        align='center'
        justify='center'
        px='15px'
        pt='30px'>
        <Text
          fontSize='md'
          color='white'
          fontWeight='bold'
          lineHeight='150%'
          textAlign='center'
          px='10px'>
          Coins balance (CCT)
        </Text>
        <Text
          fontSize={{ base: "lg", xl: "2xl" }}
          color='white'
          fontWeight='bold'
          lineHeight='150%'
          textAlign='center'
          px='10px'>
          {formatNumber(userCoins)}
        </Text>
        <Text
          fontSize='sm'
          color='white'
          px='10px'
          mb='14px'
          textAlign='center'>
          Clash Chain Tokens
        </Text>
      </Flex>
      <Flex
        direction='column'
        mb='12px'
        align='center'
        justify='center'
        px='15px'
        pt='30px'>
        <Text
          fontSize='md'
          color='white'
          fontWeight='bold'
          lineHeight='150%'
          textAlign='center'
          px='10px'>
          Staking reward
        </Text>
        <Text
          fontSize={{ base: "lg", xl: "2xl" }}
          color='white'
          fontWeight='bold'
          lineHeight='150%'
          textAlign='center'
          px='10px'>
          ${formatNumber(userEarn)}
        </Text>
        <Text
          fontSize='sm'
          color='white'
          px='10px'
          mb='14px'
          textAlign='center'>
          Total reward
        </Text>
      </Flex>
    </Flex>
  );
}
