
import { useEffect, useState } from "react";
// Chakra imports
import {
  Flex,
  Button,
  FormControl,
  SimpleGrid,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import Card from "../../../../../../components/card/Card.js";
import InputField from "../../../../../../components/fields/InputField";
import TextField from "../../../../../../components/fields/TextField";

import { ApiHeader } from '../../../../../../_helpers/ApiHeader';
import { Logout } from '../../../../../../_helpers/Auth';

export default function Information(props) {
  const { ...rest } = props;
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.600";
  // Chakra Color Mode

  const toast = useToast();
  const [isLoading, setIsLoading] = useState(props.isLoading);

  useEffect(()=>{
    setUserData({
      username: props.userData.username,
      email: props.userData.email,
      firstname: props.userData.firstname,
      lastname: props.userData.lastname,
      address: props.userData.address,
      country: props.userData.country,
      province: props.userData.province,
      zipcode: props.userData.zipcode,
      city: props.userData.city,
    });
  },[props])

  const [userData, setUserData] = useState({
    username: props.userData.username,
    email: props.userData.email,
    firstname: props.userData.firstname,
    lastname: props.userData.lastname,
    address: props.userData.address,
    country: props.userData.country,
    province: props.userData.province,
    zipcode: props.userData.zipcode,
    city: props.userData.city,
  });

  const saveUserData = () => {
    setIsLoading(true);

    let postData = {};

    userData.address ? postData.address = userData.address : postData.address = "";
    userData.country ? postData.country = userData.country : postData.country = "";
    userData.province ? postData.province = userData.province : postData.province = ""; 
    userData.zipcode ? postData.zipCode = userData.zipcode : postData.zipCode = "";
    userData.city ? postData.city = userData.city : postData.city = "";
    userData.firstname ? postData.firstName = userData.firstname : postData.firstName = "";
    userData.lastname ? postData.lastName = userData.lastname : postData.lastName = "";
    userData.username ? postData.username = userData.username : postData.username = "";

    fetch(process.env.REACT_APP_API_URL+'api/user/addUserData', {
      method: 'POST',
      body: JSON.stringify(postData),
      headers: ApiHeader('auth')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      setIsLoading(false);
      console.log(data)
      if (data.status === 1) {
        toast({
          title: 'Data saved',
          description: "Data was succesfully saved",
          status: 'success',
        })

      } else if (data.status === 401) {
        console.log('401')
        console.log(data.message)
        Logout();
      } else {
        toast({
            title: 'Error.',
            description: data.message,
            status: 'error',
          })
      }
    })
    .catch(err => {
      toast({
          title: 'Error.',
          description: "Error, please try again",
          status: 'error',
        })
        console.log("fetch error: " + err);
        setIsLoading(false);
    });
  }

  return (
    <FormControl>
      <Card mb='20px' {...rest}>
        <Flex direction='column' mb='30px' ms='10px'>
          <Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
            Account Settings
          </Text>
          <Text fontSize='md' color={textColorSecondary}>
            Here you can change user account information
          </Text>
        </Flex>
        <SimpleGrid
          columns={{ sm: 1, md: 2 }}
          spacing={{ base: "20px", xl: "20px" }}>
          <InputField
            mb='0px'
            me='30px'
            id='username'
            label='Username'
            placeholder='Username'
            value={userData.username}
            onChange={(e) => setUserData({ ...userData, username: e.target.value })}
          />
          <InputField
            mb='0px'
            id='email'
            label='Email Address'
            placeholder='Email Address'
            isReadOnly={true}
            value={userData.email}
          />
          <InputField
            mb='20px'
            me='30px'
            id='first_name'
            label='First Name'
            placeholder='First Name'
            value={userData.firstname}
            onChange={(e) => setUserData({ ...userData, firstname: e.target.value })}
          />
          <InputField
            mb='20px'
            id='last_name'
            label='Last Name'
            placeholder='Last Name'
            value={userData.lastname}
            onChange={(e) => setUserData({ ...userData, lastname: e.target.value })}
          />
        </SimpleGrid>
        <InputField
            mb='20px'
            id='address'
            label='Address'
            placeholder='Address'
            value={userData.address}
            onChange={(e) => setUserData({ ...userData, address: e.target.value })}
          />
        <SimpleGrid
          columns={{ sm: 1, md: 2 }}
          spacing={{ base: "20px", xl: "20px" }}>

          <InputField
            mb='20px'
            me='30px'
            id='zipcode'
            label='ZipCode'
            placeholder='ZipCode'
            value={userData.zipcode}
            onChange={(e) => setUserData({ ...userData, zipcode: e.target.value })}
          />
          <InputField
            mb='20px'
            me='30px'
            id='City'
            label='City'
            placeholder='City'
            value={userData.city}
            onChange={(e) => setUserData({ ...userData, city: e.target.value })}
          />
          <InputField
            mb='0px'
            id='province'
            label='Province'
            placeholder='Province'
            value={userData.province}
            onChange={(e) => setUserData({ ...userData, province: e.target.value })}
          />
          <InputField
            mb='0px'
            me='30px'
            id='country'
            label='Country'
            placeholder='Country'
            value={userData.country}
            onChange={(e) => setUserData({ ...userData, country: e.target.value })}
          />
        </SimpleGrid>
        <Button
          variant='setup'
          mt='24px'
          px='24px'
          onClick={saveUserData}
          fontSize='md'
          fontWeight='500'>
          Save
        </Button>
      </Card>
    </FormControl>
  );
}
