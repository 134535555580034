import { useState } from "react";
import { useNavigate } from 'react-router';
import { NavLink } from "react-router-dom";

import { ApiHeader } from '../../../_helpers/ApiHeader';
import { SetJwtAuth } from '../../../_helpers/Auth';

import { ethers } from 'ethers';
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "../../../components/separator/Separator";
import DefaultAuth from "../../../layouts/auth/auth";
// Assets
import illustration from "../../../assets/img/auth.png";
import { BiWallet } from "react-icons/bi";
import { MdRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

const LoginPage = () => { 

  const toast = useToast();
  const navigate = useNavigate()

  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);


    fetch(process.env.REACT_APP_API_URL+'api/auth/signin', {
      method: 'POST',
      body: JSON.stringify({
        "email" : username,
        "password" : password
      }),
      headers: ApiHeader('basic')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      setIsLoading(false);
      console.log(data)
      if (data.token) {
        SetJwtAuth(data.token);
        navigate('/admin/home')
      } else {
        console.log(data.message)
        toast({
            title: 'Error.',
            description: data.message,
            status: 'error',
          })
      }
    })
    .catch(err => {
      toast({
          title: 'Error.',
          description: "Error, please try again",
          status: 'error',
        })
        console.log("fetch error: " + err);
        setIsLoading(false);
    });
  }


  const Web3Auth = async (event) => {
    event.preventDefault();
    setIsLoading(true)

    try {
      if (window.ethereum) {
        const provider = new ethers.BrowserProvider(window.ethereum)
        const signer = await provider.getSigner()
        console.log(signer)
        provider.send("eth_requestAccounts", []).then(async () => {
          const address = await signer.getAddress()
          LoginWithAddress(signer, address);
        })
      } else {
        toast({
            title: 'Error.',
            description: "No WEB3 wallet found in your browser.",
            status: 'error',
          })
        setIsLoading(false)
      }

    } catch (ex) {
      console.log(ex)
      setIsLoading(false)
      toast({
          title: 'Error.',
          description: "Error has occured.",
          status: 'error',
        })
    }
  }

  const LoginWithAddress = async (signer, address) => {
    fetch(process.env.REACT_APP_API_URL+'api/auth/nonce', {
      method: 'POST',
      body: JSON.stringify({
        "wallet" : address,
      }),
      headers: ApiHeader('basic')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(async data => {
      setIsLoading(false);
      console.log(data)
      if (data.status === 1) {

        const msg = `Proceed to Clash Chain Portal (${data.nonce}).`
        console.log(msg)
        const signature = await signer.signMessage(msg);
        
        fetch(process.env.REACT_APP_API_URL+'api/auth/web3authlogin', {
          method: 'POST',
          body: JSON.stringify({
            "wallet" : address,
            "nonce" : data.nonce,
            "msg" : msg,
            "signature" : signature,
          }),
          headers: ApiHeader('basic')
        })
        .then(response => { 
          return response.json();
        })
        .then(responseData => {
          return responseData;
        })
        .then(data1 => {
          setIsLoading(false);
          console.log(data1)
          if (data1.token) {
            SetJwtAuth(data1.token);
            navigate(`/admin/home`)
          } else {
            console.log(data1.message)
            toast({
                title: 'Error.',
                description: data1.message,
                status: 'error',
              })
          }
        })
        .catch(err => {
          toast({
              title: 'Error.',
              description: "Error authenticating with smart wallet",
              status: 'error',
            })
            console.log("fetch error: " + err);
            setIsLoading(false);
        });

      } else {
        console.log(data.message)
        toast({
            title: 'Error.',
            description: data.message,
            status: 'error',
          })
      }
    })
    .catch(err => {
      toast({
          title: 'Error.',
          description: "Error authenticating with smart wallet",
          status: 'error',
        })
        console.log("fetch error: " + err);
        setIsLoading(false);
    });
  }

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Sign In
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
            Enter your email and password to sign in!
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
          <Button
            fontSize='sm'
            me='0px'
            mb='26px'
            py='15px'
            h='50px'
            borderRadius='16px'
            bg={googleBg}
            color={googleText}
            fontWeight='500'
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive}
            isLoading={isLoading}
            onClick={Web3Auth}>
            <Icon as={BiWallet} w='20px' h='20px' me='10px' />
            Login with Wallet
          </Button>
          <Flex align='center' mb='25px'>
            <HSeparator />
            <Text color='gray.400' mx='14px'>
              or
            </Text>
            <HSeparator />
          </Flex>
          <FormControl>
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              placeholder='Email'
              mb='24px'
              fontWeight='500'
              size='lg'
              onChange={(e) => setUsername(e.target.value)}
            />
            <FormLabel
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              display='flex'>
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size='md'>
              <Input
                fontSize='sm'
                placeholder='Min. 8 characters'
                mb='24px'
                size='lg'
                type={show ? "text" : "password"}
                variant='auth'
                onChange={(e) => setPassword(e.target.value)}
              />
              <InputRightElement display='flex' alignItems='center' mt='4px'>
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            <Flex justifyContent='space-between' align='center' mb='24px'>
              <FormControl display='flex' alignItems='center'>
                <Checkbox
                  id='remember-login'
                  colorScheme='brandScheme'
                  me='10px'
                />
                <FormLabel
                  htmlFor='remember-login'
                  mb='0'
                  fontWeight='normal'
                  color={textColor}
                  fontSize='sm'>
                  Keep me logged in
                </FormLabel>
              </FormControl>
              <NavLink to='/auth/forgot-password'>
                <Text
                  color={textColorBrand}
                  fontSize='sm'
                  w='124px'
                  fontWeight='500'>
                  Forgot password?
                </Text>
              </NavLink>
            </Flex>
            <Button
              fontSize='sm'
              variant='brand'
              fontWeight='500'
              w='100%'
              h='50'
              mb='24px'
              isLoading={isLoading}
              onClick={handleSubmit}>
              Sign In
            </Button>
          </FormControl>
          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='start'
            maxW='100%'
            mt='0px'>
            <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
              Not registered yet?
              <NavLink to='/auth/register'>
                <Text
                  color={textColorBrand}
                  as='span'
                  ms='5px'
                  fontWeight='500'>
                  Create an Account
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default LoginPage;