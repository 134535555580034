// Chakra imports
import { Image, Flex, Text, useColorModeValue } from "@chakra-ui/react";
// Assets
import React from "react";

import balanceImg from "../../../../assets/img/balanceImg.png";

import img5coin from "../../../../assets/img/5.png";
import img10coin from "../../../../assets/img/10.png";
import img25coin from "../../../../assets/img/25.png";
import img50coin from "../../../../assets/img/50.png";
import img75coin from "../../../../assets/img/75.png";
import img100coin from "../../../../assets/img/100.png";

function Buybutton(props) {
    const {
        amount,
        setAmount,
        buttonAmount,
        ...rest 
    } = props;

    const bg = useColorModeValue("brand.400", "brand.400");
    const hooverBg = useColorModeValue("#868CFF", "#868CFF");
    const borderColor = useColorModeValue("gray.300", "whiteAlpha.100");
    const textColor = useColorModeValue("black", "white");
    const coinsColor = useColorModeValue("brandgold.500", "brandgold.100");

    const buyImages = new Map();
    buyImages.set('5', img5coin);
    buyImages.set('10', img10coin);
    buyImages.set('25', img25coin);
    buyImages.set('50', img50coin);
    buyImages.set('75', img75coin);
    buyImages.set('100', img100coin);

    const balanceBg = useColorModeValue("gray.100", "#1B254B");

    return (
        <Flex
        justify='space-between'
        flexDirection='column'
        p='20px'
        mb='20px'
        borderRadius='16px'
        bgColor={amount===buttonAmount?bg:balanceBg}
        _hover={{ bgColor: hooverBg }}
        cursor='pointer'
        bgImage={balanceImg}
        bgPosition='right'
        bgSize='cover'
        onClick={()=>setAmount(buttonAmount)}>
            <Flex flexDirection='row' justify='space-between' align='center' me='20px' w='100%'>
                <Image
                    src={buyImages.get(buttonAmount.toString())}
                    w='100px'
                    maxW='60%'
                    mt={{ base: "1vh", lg: "1vh" }}
                    mb='10px'
                />
                <Text
                    color={textColor}
                    fontSize='34px'
                    fontWeight='700'
                    textAlign='right'
                    lineHeight='100%'>
                    ${buttonAmount}
                </Text>
            </Flex>
            <Text
                color={coinsColor}
                fontSize='20px'
                mt='24px'
                fontWeight='700'
                textAlign='right'
                lineHeight='100%'>
                BONUS: {buttonAmount / 0.1} CCT
            </Text>
        </Flex>
    );
}

export default Buybutton;
